import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { useRef, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { getWikiPerLetter } from "../../hooks/getWiki";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import useWindowSize from "../../hooks/useWindowSize";
import Arrow from "../../images/arrow_dark.svg";
import { useRouter } from "next/router";
import Link from "next/link";
import useEmblaCarousel from "embla-carousel-react";
import { usePrevNextButtons } from "../../utils/usePrevNextButtons";

export type FilterType = {};

interface FilterProps {
  letters?: any;
  letterHandler: (letter: string) => void;
  shallowRouting?: boolean;
}

export const Filter = ({
  letters,
  letterHandler,
  shallowRouting = true,
}: FilterProps) => {
  const {
    azFilter,
    wikiPostsPerPage,
    setLoading,
    setAzFilterPosts,
    initialLoading,
  } = useAppContext();
  const ref = useRef<HTMLDivElement>(null);
  const [fixedTop, setFixedTop] = useState(false);
  const { scrollY } = useScroll();

  const { width } = useWindowSize();

  const isSlider = width < 1287;

  const { query } = useRouter();
  const pagetype = query["pt"] ? query["pt"] : null;
  const page =
    pagetype === "wiki" ? (query["page"] ? Number(query["page"]) : 1) : 1;

  useUpdateEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      if (azFilter !== "all") {
        const wikiData = await getWikiPerLetter(
          azFilter,
          wikiPostsPerPage,
          page
        );
        // console.log("set letter: " + azFilter);
        // update global state here on context
        setAzFilterPosts(wikiData);
      } else {
        // update global state here on context
        // console.log("set Filter all");
        const wikiData = await getWikiPerLetter(null, wikiPostsPerPage, page);
        setAzFilterPosts(wikiData);
      }
      setLoading(false);
    };
    if (!initialLoading) {
      fetchPosts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [azFilter]);

  useMotionValueEvent(scrollY, "change", (scrollValue) => {
    const topOffset = ref.current?.getBoundingClientRect().top;
    if (scrollValue > 200 && topOffset && topOffset <= 60) {
      setFixedTop(true);
    } else {
      setFixedTop(false);
    }
  });

  const Letters = letters ? Object.keys(letters) : [];
  const allLetters = Letters.map((el, i) => (
    <Letter
      extraClasses={`${el === azFilter ? "active" : ""}`}
      key={i}
      callback={() => letterHandler(el)}
      letter={el}
      shallowRouting={shallowRouting}
    />
  ));
  return (
    <div className="ref-container" ref={ref}>
      <motion.div
        transition={{
          easings: ["circIn", "easeInOut"],
        }}
        className={`letters-container ${fixedTop ? "fixed-top" : "normal"} `}
      >
        {!isSlider && (
          <>
            <Letter
              extraClasses={`all ${azFilter === "all" ? "active" : ""}`}
              callback={() => letterHandler("all")}
              letter={"all"}
              //todo add label
              displayLetter={"Alle"}
              shallowRouting={shallowRouting}
            />

            {allLetters}
          </>
        )}
        {isSlider && (
          <FilterSlider
            letterHandler={letterHandler}
            letters={Letters}
            shallowRouting={shallowRouting}
          />
        )}
      </motion.div>
    </div>
  );
};

const FilterSlider = ({
  letters = [],
  letterHandler,
  shallowRouting,
}: {
  letters: string[];
  letterHandler: (letter: string) => void;
  shallowRouting?: boolean;
}) => {
  const { azFilter } = useAppContext();

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    align: "start",
  });

  const {
    nextBtnDisabled,
    prevBtnDisabled,
    onNextButtonClick,
    onPrevButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <div className="filter-slider">
      <motion.button
        initial={{
          rotate: 180,
        }}
        whileTap={{ scale: 0.8 }}
        className="prev"
        aria-label="prev"
        disabled={prevBtnDisabled}
        onClick={onPrevButtonClick}
      >
        <Arrow width={21} height={12} />
      </motion.button>

      <div ref={emblaRef} className="embla-slider">
        <ul className="embla-container">
          <li className="embla-slide">
            <Letter
              extraClasses={`all ${azFilter === "all" ? "active" : ""}`}
              callback={() => letterHandler("all")}
              letter={"all"}
              //todo add label
              displayLetter={"Alle"}
              shallowRouting={shallowRouting}
            />
          </li>
          {letters.map((letter) => {
            return (
              <li className="embla-slide" key={letter}>
                <Letter
                  extraClasses={`${letter === azFilter ? "active" : ""}`}
                  callback={() => letterHandler(letter)}
                  letter={letter}
                  shallowRouting={shallowRouting}
                />
              </li>
            );
          })}
        </ul>
      </div>

      {/* <Swiper
        slidesPerView={"auto"}
        speed={400}
        freeMode
        modules={[Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        <SwiperSlide style={{ width: "min-content" }}>
          <Letter
            extraClasses={`all ${azFilter === "all" ? "active" : ""}`}
            callback={() => letterHandler("all")}
            letter={"all"}
            //todo add label
            displayLetter={"Alle"}
            shallowRouting={shallowRouting}
          />
        </SwiperSlide>
        {letters.map((letter) => {
          return (
            <SwiperSlide style={{ width: "min-content" }} key={letter}>
              <Letter
                extraClasses={`${letter === azFilter ? "active" : ""}`}
                callback={() => letterHandler(letter)}
                letter={letter}
                shallowRouting={shallowRouting}
              />
            </SwiperSlide>
          );
        })}
      </Swiper> */}
      <motion.button
        className="next"
        aria-label="next"
        whileTap={{ scale: 0.8 }}
        onClick={onNextButtonClick}
        disabled={nextBtnDisabled}
      >
        <Arrow width={21} height={12} />
      </motion.button>
    </div>
  );
};

interface LetterProps {
  letter: string;
  displayLetter?: string;
  extraClasses?: string;
  callback: Function;
  shallowRouting?: boolean;
}

const Letter = ({
  letter,
  extraClasses,
  callback,
  displayLetter,
  shallowRouting = true,
}: LetterProps) => {
  const { query } = useRouter();

  const dynamicRoute = query["dynamicRoute"]
    ? (query["dynamicRoute"] as string[])
    : [];

  const path = dynamicRoute[0];

  return (
    <Link
      href={`${path}?pt=wiki&page=1&letter=${letter}`}
      as={`${path}/?page=1&pt=wiki&letter=${letter}`}
      className={`letter ${extraClasses}`}
      prefetch={false}
      onClick={() => callback()}
      scroll={false}
      shallow={shallowRouting}
    >
      {displayLetter ? displayLetter : letter}
    </Link>
  );
};

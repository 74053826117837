import React from "react";
import { ImageType, LayoutType, LinkType } from "../../utils/baseTypes";
import { Section } from "../Global/Section";
import { motion } from "framer-motion";
import {
  blur_opacity_variant,
  line_variant,
  opacity_variant,
  slide_variant,
  stagger_variant,
} from "../../utils/transition";
import Headline from "../Global/Headline";
import Link from "next/link";

import Arrow from "../../images/arrow_dark.svg";
import Image from "next/image";
import { sanitize } from "isomorphic-dompurify";
import useWindowSize from "../../hooks/useWindowSize";

import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

const MotionHeadline = motion(Headline);
const MotionLink = motion(Link);

export type AwardsType = {
  acf_fc_layout: "awards";
  awards: {
    headline: string;
    overline: string;
    htype_tag: "h2" | "h3";
    link: LinkType;
    elements: AwardElementType[];
  };
  layout: LayoutType;
};

interface AwardElementType {
  icon: ImageType;
  link: LinkType;
}

interface AwardsProps {
  data: AwardsType;
}

const Line = ({ animation = "clip" }: { animation?: "clip" | "opacity" }) => {
  return (
    <motion.span className="line-wrapper">
      <motion.span
        variants={animation === "clip" ? line_variant : opacity_variant}
        className="line"
      ></motion.span>
    </motion.span>
  );
};

const Awards = ({ data }: AwardsProps) => {
  const { abstand, background, id } = data.layout;
  const gap = abstand === "default" ? "" : abstand;

  const { htype_tag, headline, link, elements, overline } = data.awards;

  const { width } = useWindowSize();

  const isGrid =
    width > 768
      ? Array.isArray(elements)
        ? elements.length < 5
        : true
      : false;

  return (
    <Section id={id} className={`awards ${gap} ${background}`} disableColumns>
      <motion.div
        variants={stagger_variant}
        whileInView={"animate"}
        initial={"initial"}
        viewport={{ once: true }}
        className="columns is-multiline"
      >
        <div className="column is-12">
          <span className="overline-wrapper">
            <motion.span
              variants={blur_opacity_variant}
              className="overline-text"
              dangerouslySetInnerHTML={{
                __html: sanitize(overline),
              }}
            />
            <Line />
          </span>
        </div>
        <motion.div
          variants={blur_opacity_variant}
          className="column is-6-desktop is-4-widescreen border-right"
        >
          <MotionHeadline
            headline={headline}
            htype_tag={htype_tag}
            className="mb-4"
          />
          {link && (
            <AwardLink
              link={link}
              className="is-hidden-touch is-hidden-desktop-only"
            />
          )}
        </motion.div>
        <div className="column  is-hidden-mobile is-hidden-widescreen tablet-cta-wrapper">
          <div className="mb-4">{link && <AwardLink link={link} />}</div>
        </div>
        {/* <motion.div
          variants={opacity_variant}
          className="column is-1-desktop middle-line is-hidden-touch is-hidden-desktop-only"
        /> */}
        <div
          className={`column is-12 is-8-widescreen award-elements ${
            isGrid ? "is-grid" : "is-slider"
          }`}
        >
          {isGrid && <AwardsGrid elements={elements} />}

          {!isGrid && <AwardsSlider elements={elements} />}
        </div>
        <div className="column is-12 is-hidden-tablet mt-4">
          {link && <AwardLink link={link} />}
        </div>
        <div className="column is-12">
          <div className="bottom-line-wrapper">
            <Line />
          </div>
        </div>
      </motion.div>
    </Section>
  );
};

const AwardLink = ({
  link,
  className = "",
}: {
  link: LinkType;
  className?: string;
}) => {
  return (
    <MotionLink
      variants={blur_opacity_variant}
      href={link.url}
      target={link.target}
      className={`awards-link ${className}`}
    >
      <span>{link.title}</span>
      <span className="arrow">
        <Arrow width={22.06} height={14.58} />
      </span>
    </MotionLink>
  );
};

const AwardElement = ({
  element,
  index,
  animation = true,
}: {
  element: AwardElementType;
  index: number;
  animation?: boolean;
}) => {
  const { icon, link } = element;
  return (
    <MotionLink
      variants={animation ? slide_variant : {}}
      key={index}
      href={link ? link.url : ""}
      target={link ? link.target : "_self"}
      className="award-element stripe-atag"
      aria-label="award-element"
    >
      {icon && (
        <Image
          src={icon.url ?? ""}
          alt={icon.alt}
          data-title={icon.title}
          width={icon.width}
          height={icon.height}
          style={{ objectFit: "contain", objectPosition: "center" }}
          sizes="(max-width: 375px) 50vw, (max-width: 823px) 25vw, (max-width: 1216px) 20vw, 10vw"
        />
      )}
    </MotionLink>
  );
};

const AwardsGrid = ({ elements }: { elements: AwardElementType[] }) => {
  return (
    <>
      {Array.isArray(elements) &&
        elements.map((element, index) => {
          return <AwardElement key={index} element={element} index={index} />;
        })}
    </>
  );
};

const AwardsSlider = ({ elements }: { elements: AwardElementType[] }) => {
  const autoplayOptions = {
    delay: 0,
    rootNode: (emblaRoot: HTMLElement) => emblaRoot.parentElement,
    stopOnInteraction: false,
    stopOnMouseEnter: true,
    stopOnFocusIn: false,
  };

  const [emblaRef] = useEmblaCarousel(
    {
      loop: true,
      dragFree: true,
      duration: 10000,
      align: "center",
    },
    [Autoplay(autoplayOptions)]
  );

  return (
    <motion.div variants={blur_opacity_variant} className="is-clipped mr-4">
      <div ref={emblaRef} className="embla-slider">
        <ul className="embla-container">
          {elements.map((element, index) => {
            return (
              <li className="embla-slide" key={index}>
                <AwardElement
                  element={element}
                  index={index}
                  animation={false}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </motion.div>
  );
};
export default Awards;

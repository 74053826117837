import { useState, useEffect } from "react";

import {
  PagenationDataType,
  CategoryDataType,
  PageType,
} from "../../utils/baseTypes";
import { InsightElement } from "./InsightElement";
import { InsightsModuleType } from "./Insights";
import useEmblaCarousel from "embla-carousel-react";

interface InsightSliderProp {
  data: InsightsModuleType;
  InsightData: PagenationDataType;
  InsightCategoryData: CategoryDataType[];
  InsightHighlightedPosts: PageType[];
}

export const InsightSlider = ({
  InsightCategoryData,
  InsightData,
  InsightHighlightedPosts,
}: InsightSliderProp) => {
  const [insightData, setInsightData] = useState<PageType[]>([]);
  const [categoryPath, setCategoryPath] = useState<string>("");

  useEffect(() => {
    if (InsightCategoryData.length > 0) {
      const first = InsightCategoryData[0].link.replace(/\/$/, "");
      const path = first.substring(0, first.lastIndexOf("/"));
      setCategoryPath(path);
    }
  }, [InsightCategoryData]);

  useEffect(() => {
    setInsightData(
      Array.isArray(InsightData.posts)
        ? InsightHighlightedPosts.concat(InsightData.posts)
        : InsightHighlightedPosts
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [InsightData]);

  const [emblaRef] = useEmblaCarousel({
    loop: true,
    dragFree: true,
    align: "center",
    watchSlides: true,
  });

  return (
    <div className="column is-12">
      <div ref={emblaRef} className="embla-slider">
        <ul className="embla-container">
          {Array.isArray(insightData) &&
            insightData.map((insight, index) => (
              <li className="embla-slide" key={index}>
                <InsightElement
                  insight={insight}
                  index={index}
                  categoryPath={categoryPath}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
